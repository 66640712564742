.react-calendar-heatmap-month-labels {
  font-size: 7.5px;
}

.headings {
  margin-left: 15px;
}

.react-calendar-heatmap .color-empty { fill: #808080; }
.react-calendar-heatmap .color-scale-red { fill: #FF6347; }
.react-calendar-heatmap .color-scale-amber { fill: 	#FFA500; }
.react-calendar-heatmap .color-scale-green { fill: #8cc665; }